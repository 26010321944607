import "./App.css";
import { useEffect } from "react";
import TitleRow from "./routes/root/TitleRow";
import FooterRow from "./routes/root/FooterRow";
import { Outlet } from "react-router-dom";
import NavigationList from "./routes/root/NavigationList";

function App() {
  useEffect(() => {
    document.body.style.backgroundColor = "#C4E1F2";
  });

  return (
    <div>
      <TitleRow />
      <div className="row">
        <div className="column column-nav">
          <NavigationList />
        </div>
        <div className="column column-main">
          <Outlet />
        </div>
      </div>
      <FooterRow />
    </div>
  );
}

export default App;
