import { Link, useLocation } from "react-router-dom";

export default function NavigationList() {
  const location = useLocation();
  const links = [
    {
      to: "/",
      text: "Home",
    },
    // {
    //   to: "/weekly-picks",
    //   text: "Picks",
    // },
    // {
    //   to: "/profit",
    //   text: "Profit",
    // },
  ];
  const result = links.map((link, index) => (
    <NavigationItem
      key={index}
      to={link.to}
      isCurrent={location.pathname === link.to}
      text={link.text}
    />
  ));
  return <>{result}</>;
}

function NavigationItem({ to, isCurrent, text }) {
  return (
    <Link style={{ textDecoration: "none" }} to={to}>
      <div className="route-link">{isCurrent ? text + " ✔" : text}</div>
    </Link>
  );
}
