import "./Blog.css";

export default function Blog() {
  return (
    <div className="Blog">
      <div className="Blog-title">2022 in Review</div>
      <div className="Blog-date">January 6th, 2023</div>
      <div className="Blog-content">
        <p>
          Year 2022 was an eventful year, I can probably summarize it in two
          words: freedom and money. In November of 2022, I decided to quit my
          job. I wanted to take some time off because I hadn't seen my family in
          nearly three years (since the Covid outbreak) and I truly believed I
          had earned it. I hadn't taken any time off between jobs, and I hadn't
          taken a vacation for more than 10 days. The feeling was great. I
          didn't have to worry about finding a new job (at least not yet) and it
          was a great feeling to wake up each day without worrying about work. I
          was finally able to spend some time looking into hobbies that I had
          always been interested in.
        </p>
        <p>
          There are two parts to the money aspect of 2022. The first part is
          directly related to the freedom I mentioned earlier. When you're
          taking a long break between jobs, there is no income coming in. I have
          savings that will last me for another two years if I choose not to
          work, but it's still not enjoyable to see my savings shrink. Do I
          regret taking a long time off without income? No, because the long
          break was meant to help me get excited about work again. It also gave
          me a taste of retirement life, and verified what influencers and
          vloggers have said about how taking a long break has helped them.
        </p>
        <p>
          The second part of the money aspect of 2022 is that it seems to be the
          starting year of a bear market. My portfolio was down 20% compared to
          2021's year-end. It sucks, but that seems to be the case for everyone
          (at least for retail traders like me). The bear market seems to have
          been triggered by high inflation and the Federal Reserve trying to
          combat it by raising interest rates. At the time of writing, it seems
          that inflation has cooled off, but it's still at around 9%, which is
          far from the targeted 2%. In other words, the good news is that
          inflation seems to have reached its peak, but since it's still not at
          the targeted 2%, the Federal Reserve will not stop raising interest
          rates in 2023.
        </p>
        <p>
          For me, 2023 will be a year of caution in trading. I am likely not
          going to hold anything long term and will just keep cash on hand.
        </p>
      </div>
      <span className="Blog-tag">Blog</span>
    </div>
  );
}
